import { extractObjectValues } from './extractObjectValues';
/**
 * Contentful field for supported locales before price and up
 */
export const supportedLocales = extractObjectValues(window?.AppData?.contentfulEnvSettings?.ITFE_MARINE_MAPS_SUPPORTED_LOCALES_BEFORE_PRICE);

/**
 * Check if the locale is supported for before price and up
 * @param {string} locale - The locale to check
 * @returns {boolean}     - True if the locale is supported, false otherwise
 */
export const shouldDisplayBeforePriceAndUp = (locale) => supportedLocales.includes(locale);
