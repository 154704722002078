<script setup>
/* Props */
const props = defineProps({
  translations: {
    type: Object,
    default: () => ({}),
  },
  mapViewType: {
    type: String,
    default: '',
  },
});

/* Emits */
const emit = defineEmits(['changeMapView']);

/* Methods */
const changeMapViewType = (event) => {
  emit('changeMapView', event?.target?.value);
};
</script>

<template>
  <div class="map-view-options">
    <g-heading
      class="map-view-options__heading"
      :content="props.translations.ITFE_MARINE_MAPS_VIEW"
      heading-size="4"
    />
    <g-radio :label="props.translations.ITFE_MARINE_MAPS_MAP">
      <input
        name="view"
        type="radio"
        value="map"
        :checked="props.mapViewType === 'map'"
        :data-checked="props.mapViewType === 'map'"
        @change="changeMapViewType($event)"
      >
    </g-radio>
    <g-radio :label="props.translations.ITFE_MARINE_MAPS_HEATMAP">
      <input
        name="view"
        type="radio"
        value="heatmap"
        :checked="props.mapViewType === 'heatmap'"
        :data-checked="props.mapViewType === 'heatmap'"
        @change="changeMapViewType($event)"
      >
    </g-radio>
  </div>
</template>

<style lang="scss" scoped>
@include map-settings('map-view-options');
</style>
