<script setup>
/* Imports */
import {
  inject,
  ref,
  computed,
  onBeforeMount,
} from 'vue';

/* Inject */
const translations = inject('translations');
const isWorldwideUpdates = inject('isWorldwideUpdates');
const worldwideNumberOfUpdates = inject('worldwideNumberOfUpdates');
const polygonNumberOfUpdates = inject('polygonNumberOfUpdates');
const fetchNumberOfUpdateMultiPolygon = inject('fetchNumberOfUpdateMultiPolygon');
const fetchWorldwideNumberOfUpdates = inject('fetchWorldwideNumberOfUpdates');
const selectedPeriod = inject('selectedPeriod');
const setSelectedPeriod = inject('setSelectedPeriod');
const updatesTimeframes = inject('updatesTimeframes');

/* Props */
const props = defineProps({
  mapMultiPolygonCoordinates: {
    type: Object,
    default: () => ({}),
  },
});

/* State */
const state = ref({
  periodOfTimeOfUpdates: [
    {
      key: '2_years',
      label: translations.value.ITFE_MARINE_MAPS_TWO_YEARS,
    },
    {
      key: '1_year',
      label: translations.value.ITFE_MARINE_MAPS_ONE_YEAR,
    },
    {
      key: '6_months',
      label: translations.value.ITFE_MARINE_MAPS_SIX_MONTHS,
    },
  ],
  isLoadingUpdates: true,
});

/* Computed - Title Heading */
const computedTitleHeading = computed(() => (
  isWorldwideUpdates.value
    ? translations.value.ITFE_MARINE_MAPS_WORLDWIDE_UPDATES
    : translations.value.ITFE_MARINE_MAPS_NUMBER_OF_UPDATES));

/**
 * Retrieve selected timeframe
 * e.g {
 * from: '2022-12-31T22:00:00000Z'
 * to: '2023-12-31T22:00:00000Z'
 * }
 */
const selectedTimeframe = computed(() => updatesTimeframes[selectedPeriod.value]);

/* Number of updates that is rendered */
const numberOfUpdates = computed(() => {
  const value = isWorldwideUpdates.value ? worldwideNumberOfUpdates.value : polygonNumberOfUpdates.value;
  return parseInt(value, 10) ? parseInt(value, 10).toLocaleString() : '';
});

/**
 * In order to fetch the number of updates successfully,
 * the pairs of coordinates provided need to be swaped.
 *
 * Background: Leaflet uses [lat, lng] and the API uses [lng, lat]
 */
/* eslint-disable max-len */
const polygonCoordinates = computed(() => props.mapMultiPolygonCoordinates?.map((polygon) => polygon[0].map((coordinates) => [coordinates[1], coordinates[0]])));

/* Load the number of updates */
const loadNumberOfUpdates = async () => {
  if (isWorldwideUpdates.value) {
    state.value.numberOfUpdates = await fetchWorldwideNumberOfUpdates({ ...selectedTimeframe.value });
  } else {
    state.value.numberOfUpdates = await fetchNumberOfUpdateMultiPolygon({
      ...selectedTimeframe.value,
      coordinates: polygonCoordinates.value,
    });
  }
};

/* Update the number of updates */
const updateNumberOfUpdates = async (e) => {
  setSelectedPeriod(e?.target?.value);
  state.value.isLoadingUpdates = true;
  await loadNumberOfUpdates();
  state.value.isLoadingUpdates = false;
};

/* Lifecycle - Before Mount - Load the number of updates */
onBeforeMount(async () => {
  await loadNumberOfUpdates();
  state.value.isLoadingUpdates = false;
});
</script>

<template>
  <div class="map-card-number-of-updates">
    <g-heading
      :content="computedTitleHeading"
      heading-size="4"
      data-testid="map-card-number-of-updates-title"
    />

    <div class="map-card-number-of-updates__container">
      <g-loader
        v-if="state.isLoadingUpdates"
        type="dark"
        class="map-card-number-of-updates__loader"
        data-testid="map-card-number-of-updates-loader"
      />

      <g-copy
        v-else
        class="map-card-number-of-updates__number"
        data-testid="map-card-number-of-updates-number"
      >
        {{ numberOfUpdates }}
      </g-copy>

      <g-drop-down
        :label="translations.ITFE_MARINE_MAPS_NUMBER_OF_UPDATES_LABEL"
        hide-label="true"
        class="map-card-number-of-updates__dropdown"
        @click.stop
        @change="updateNumberOfUpdates($event)"
      >
        <select
          id="periodOfTime"
          name="periodOfTime"
          :value="selectedPeriod"
        >
          <option
            v-for="periodOfTimeOfUpdate in state.periodOfTimeOfUpdates"
            :key="periodOfTimeOfUpdate.key"
            :value="periodOfTimeOfUpdate.key"
          >
            {{ periodOfTimeOfUpdate.label }}
          </option>
        </select>
      </g-drop-down>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.map-card-number-of-updates {

  &__container {
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 1.75rem;
  }

  &__number {
    font-size: 2rem;
    font-family: $font-oswald;
    margin: 0.875rem 0 1.125rem 0;
    width: auto;
    // Edge case for long numbers -> maybe never happens :-) just in case
    word-break: break-word;

    @include breakpoint('md') {
      font-size: 2.5rem;
    }
  }

  &__dropdown {
    width: auto;
    padding: 1rem 0;
  }

  .g__dropdown select {
    width: auto;
  }

  :deep(.g__dropdown__arrow) {
    position: relative;
    margin-left: -1.5rem;
    right: 0;
  }

  &__loader {
    width: 4.6875rem; // 75px
    padding: 1rem 0;
  }

  :deep(.g__heading) {

    h4 {
      font-size: 1.25rem;
      margin: 0;
      text-transform: uppercase;
      // Edge case for long titles -> maybe never happens :-) just in case
      word-break: break-word;
    }
  }
}
</style>
