<script setup>
/* Imports */
import { inject } from 'vue';

/* Inject */
const translations = inject('translations');

/* Props */
const props = defineProps({
  map: {
    type: Object,
    default: () => ({}),
  },
});

/* Map SKU buy URL */
const mapSkuBuyUrl = props.map?.skuBuyUrl;
</script>

<template>
  <div class="shop-now-product-card-btn">
    <template v-if="mapSkuBuyUrl">
      <g-button
        :href="mapSkuBuyUrl"
        class="shop-now-product-card-btn__shop-now-button"
        size="medium"
        data-testid="shop-now-button"
      >
        {{ translations.ITFE_MARINE_MAPS_RESULT_BUTTON_TEXT }}
      </g-button>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.shop-now-product-card-btn {
  display: flex;
  margin-top: auto; // Ensures button is pushed to bottom on card on mobile
  cursor: auto;

  @include breakpoint('sm') {
    justify-content: flex-end;
    margin-top: 0;
  }

  &__shop-now-button {
    margin: 0;
  }

  :deep(.g__button__host) {
    width: 100%;

    @include breakpoint('sm') {
      width: auto;
    }
  }
}
</style>
