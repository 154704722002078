/**
 * This script calculates great-circle distances between the two points – that is,
 * the shortest distance over the earth’s surface – using the ‘Haversine’ formula.
 * More Info:
 * http://www.movable-type.co.uk/scripts/latlong.html
 * https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
 */

/**
 * Converts degrees to radius
 * @param {number} deg  - Degrees to convert
 * @returns {number}    - Radians
 */
const deg2rad = (deg) => deg * (Math.PI / 180);

/**
 * Calculates the distance between two points in km
 * @param {number} lat1  - Latitude from point 1
 * @param {number} long1 - Longitude from point 1
 * @param {number} lat2  - Latitude from point 2
 * @param {number} long2 - Longitude from point 2
 * @returns {number}     - Distance in km
 */
export const getDistanceFromLatLong = (lat1, long1, lat2, long2) => {
  const earthRadiusKM = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(long2 - long1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadiusKM * c; // Distance in km
  return distance;
};

export default getDistanceFromLatLong;
