import gql from 'graphql-tag';

export default gql`
  query ($name: String!, $isGarminMap: Boolean!) {
    getLakesByName(name: $name, isGarminMap: $isGarminMap) {
      id
      name
      name2
      information
      group_id
      bathymetry_type
      category_of_source
      counties
      states
      country
      elevation
      features
      rpd_region_code
      center_lat
      center_lon
    }
  }
`;
