import { computed } from 'vue';
import { useStore } from 'vuex';

/**
 * This helper is used to map the state, getters, mutations and
 * actions of the vuex-store to the component.
 * This feature was removed into the new version of vuex-vue3,
 * and this helper is used to bring it back.
 *
 * Note: If this project will be moved to Pinia, this helper can be removed.
 *
 */

/**
 * Helper function to display a warning message when Vuex store is not found.
 * @param {string} feature - The feature that is being used.
 * @returns {void}         - Displays a warning message.
 */
// eslint-disable-next-line no-console
const warnNoStore = (feature) => console.warn(`${feature}: No Vuex store found. Make sure to use this function within a Vuex-enabled component.`);

export const mapState = () => {
  const store = useStore();
  const stateMap = {};

  if (store) {
    Object.keys(store.state).forEach((key) => {
      stateMap[key] = computed(() => store.state[key]);
    });
  } else {
    warnNoStore('mapState');
  }

  return stateMap;
};

export const mapGetters = () => {
  const store = useStore();
  const getterMap = {};

  if (store) {
    Object.keys(store.getters).forEach((getter) => {
      getterMap[getter] = computed(() => store.getters[getter]);
    });
  } else {
    warnNoStore('mapGetters');
  }

  return getterMap;
};

export const mapMutations = () => {
  const store = useStore();
  const mutationMap = {};

  if (store) {
    // eslint-disable-next-line no-underscore-dangle
    Object.keys(store._mutations).forEach((mutation) => {
      mutationMap[mutation] = (value) => store.commit(mutation, value);
    });
  } else {
    warnNoStore('mapMutations');
  }

  return mutationMap;
};

export const mapActions = () => {
  const store = useStore();
  const actionMap = {};

  if (store) {
    // eslint-disable-next-line no-underscore-dangle
    Object.keys(store._actions).forEach((action) => {
      actionMap[action] = (value) => store.dispatch(action, value);
    });
  } else {
    warnNoStore('mapActions');
  }

  return actionMap;
};
