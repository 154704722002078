<script setup>
/* Imports */
import {
  nextTick,
  markRaw,
  onBeforeUnmount,
  onMounted,
  provide,
  ref,
  useAttrs,
  useSlots,
} from 'vue';

/* Helpers */
import {
  mapActions,
} from '../helpers/mainHelpers';

// This 2 imports are based on https://github.com/jperelli/vue2-leaflet-googlemutant/blob/master/Vue2LeafletGoogleMutant.vue
import L from 'leaflet';
// This package exists in node_modules but the eslint import is unable to resolve it
// and considers it a lint error.
/* eslint-disable import/no-unresolved */
import '../modules/LeafletGridLayerGoogleMutant/Leaflet.GoogleMutant';

import { InjectionKeys, Functions, Utilities } from '@vue-leaflet/vue-leaflet';

const { propsBinder, assertInject } = Utilities;
const { AddLayerInjection, RemoveLayerInjection } = InjectionKeys;
const { setupLayer } = Functions.Layer;

/* Store - Actions */
const {
  setGoogleMapsApi,
} = mapActions();

/* Props */
const props = defineProps({
  options: {
    type: Object,
    default() { return {}; },
  },
  apikey: {
    type: String,
    default() { return ''; },
  },
  lang: {
    type: String,
    default: null,
  },
  region: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: '',
  },
  layerType: {
    type: String,
    default: 'base',
  },
  visible: {
    type: Boolean,
    default: true,
  },
});

/* Events */
const emit = defineEmits(['ready']);

// The following lines are based on https://github.com/jperelli/vue2-leaflet-googlemutant/blob/master/Vue2LeafletGoogleMutant.vue
// updated to Vue 3 syntax based on:
// https://github.com/M-Media-Group/Cartes.io-Web-App/blob/92e86ce488bba9426124d441a7a35cd5956a946c/src/components/maps/MarkerCluster.vue
const leafletObject = ref({});
const ready = ref(false);

const addLayer = assertInject(AddLayerInjection);
const removeLayer = assertInject(RemoveLayerInjection);

provide('canSetParentHtml', () => !!leafletObject.value.getElement());
/* eslint-disable no-return-assign */
provide(
  'setParentHtml',
  (html) => (leafletObject.value.getElement().innerHTML = html),
);
/* eslint-enable no-return-assign */

const slots = useSlots();
const attrs = useAttrs();

const context = { props, attrs, slots };

const { methods } = setupLayer(props, leafletObject.value, context);

// New loadedGoogleMapsAPI using async
const loadedGoogleMapsAPI = () => {
  const loadMapsAPI = new Promise((resolve) => {
    // eslint-disable-next-line no-undef
    if (!(typeof google === 'object' && typeof google.maps === 'object')) {
      // Callback provided to Google Maps API,
      // once this resolves, the window.google object will be populated
      window.GoogleMapsInit = resolve;

      const googleMapsScript = document.createElement('script');
      let scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${props.apikey}&callback=GoogleMapsInit`;
      scriptUrl += props.lang ? `&language=${props.lang}` : '';
      scriptUrl += props.region ? `&region=${props.region}` : '';
      googleMapsScript.setAttribute('src', scriptUrl);
      document.head.appendChild(googleMapsScript);
    }
  });
  return loadMapsAPI;
};

onMounted(async () => {
  leafletObject.value = markRaw(L.gridLayer.googleMutant(props.options));
  const emitter = (key) => (e) => context.emit(key, e);

  const remapEvents = () => {
    const events = [
      'layeradd',
      'layerremove',
      'click',
      'dblclick',
      'mousedown',
      'mouseup',
      'mouseover',
      'mouseout',
      'contextmenu',
    ];
    const listeners = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const event of events) {
      listeners[event] = emitter(event);
    }

    return listeners;
  };
  leafletObject.value?.on(remapEvents());

  propsBinder(methods, leafletObject.value, props);
  loadedGoogleMapsAPI().then(() => {
    // replace the provided addLayer function for child components of GoogleMutantLayer so they add to that layer rather than the map
    addLayer({
      ...props,
      ...methods,
      leafletObject: leafletObject.value,
    });
    ready.value = true;

    setGoogleMapsApi(window.google);
    nextTick(() => emit('ready', leafletObject.value, window.google));
  });
});

onBeforeUnmount(
  () => (leafletObject?.value ? removeLayer({ leafletObject: leafletObject.value }) : null),
);
</script>

<template>
  <div style="display: none;">
    <slot v-if="ready" />
  </div>
</template>
