/**
 * Insignia breakpoints
 */
export const breakpoints = {
  xs: '480',
  sm: '768',
  md: '1024',
  lg: '1200',
  xl: '1350',
  xxl: '1440',
  xxxl: '1600',
};

export default breakpoints;
