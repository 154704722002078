<script setup>
/* Imports */
import { ref } from 'vue';
import PoweredByGoogle from '../../../public/powered_by_google.png';

/* State */
const state = ref({
  poweredByGoogleImgPath: PoweredByGoogle,
});
</script>

<template>
  <div class="google-image__powered-by-google">
    <img
      :src="state.poweredByGoogleImgPath"
      alt="Search powered by Google"
    >
  </div>
</template>

<style lang="scss" scoped>
.google-image {

  &__powered-by-google {
    display: flex;
    padding: 0.5em 0.5em 0.5em 0;
    justify-content: flex-start;
  }
}
</style>
