/**
 * Composes a series of functions into a single function that processes
 * input from left to right.
 * @param {...Function} funcs - The functions to compose. They are executed
 *   from left to right.
 * @returns {Function} A new function that represents the composition of the
 *   input functions. When invoked, it applies each function in sequence,
 *   passing the result of each function to the next one.
 * @example
 * // Usage example
 * const add = x => x + 1;
 * const double = x => x * 2;
 * const addThenDouble = flow(add, double);
 * console.log(addThenDouble(3)); // Output: 8
 */
export const flow = (...funcs) => {
  const { length } = funcs;

  // Check if all arguments are functions
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    if (typeof funcs[i] !== 'function') {
      throw new TypeError('Expected a function');
    }
  }

  // eslint-disable-next-line func-names
  return function (...args) {
    let result = length ? funcs[0].apply(this, args) : args[0];
    // eslint-disable-next-line no-plusplus
    for (let j = 1; j < length; j++) {
      result = funcs[j].call(this, result);
    }
    return result;
  };
};

export default flow;
