import { createApp } from 'vue';

import vue3TouchEvents from 'vue3-touch-events';
import router from './router/router';
import store from './store/globalStore';

import App from './App.vue';

import sentry from './modules/sentryClient';
import leafletComponents from './modules/leafletComponents';
// eslint-disable-next-line no-unused-vars
import * as registerComponents from './modules/registerComponents';
import { setupGlobalProperties } from './modules/globalProperties';

const app = createApp(App);

setupGlobalProperties(app);
app.use(router);
app.use(store);
// Initialize sentry-client
sentry(app);
// Handle leaflet and make it global
app.use(leafletComponents);
// Initialize vue3-touch-events
app.use(vue3TouchEvents);

app.mount('#app');
