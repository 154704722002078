import gql from 'graphql-tag';

export default gql`
  query ($skus: [String!], $locale: String!)
  {
    getPromotedPricing(skus: $skus, locale: $locale) {
      formattedSalePrice
      formattedListPrice
      currencyCode
      sku
    }
  }
`;
