import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LControlAttribution,
  LPolygon,
  LControlZoom,
  LControl,
} from '@vue-leaflet/vue-leaflet';

import { LMarkerClusterGroup } from 'vue-leaflet-markercluster';

import 'leaflet/dist/leaflet.css';

/**
 * Registers the Leaflet components with the Vue application.
 * @param {import('vue').default} app - The Vue instance representing the application.
 * @returns {void}
 */
export default function registerLeaflet(app) {
  app
    .component('LMap', LMap)
    .component('LTileLayer', LTileLayer)
    .component('LMarker', LMarker)
    .component('LPopup', LPopup)
    .component('LControlAttribution', LControlAttribution)
    .component('LPolygon', LPolygon)
    .component('LControlZoom', LControlZoom)
    .component('LControl', LControl)
    .component('LMarkerClusterGroup', LMarkerClusterGroup);
}
