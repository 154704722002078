import SmoothScroll from '@garmin/smooth-scroll';

export default (to) => {
  if (to.hash) {
    return SmoothScroll(`[id='${to.hash.split('#')[1]}']`);
  }

  return new Promise((resolve) => {
    if (to.matched.some((m) => m.meta?.scrollTo)) {
      setTimeout(() => {
        SmoothScroll(to.meta.scrollTo, 'top');
      }, 500);
      resolve();
    }

    resolve();
  });
};
