<script setup>
/* Imports */
import { computed, inject } from 'vue';

/* Inject */
const showGarminMaps = inject('showGarminMaps');

/* Props */
const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  activeLake: {
    type: Object,
    default: () => ({}),
  },
  showLakeInfo: {
    type: Boolean,
    default: false,
  },
  translations: {
    type: Object,
    default: () => ({}),
  },
});

/* Computed */
const tableHeadingRight = computed(() => (showGarminMaps.value
  ? props.translations.ITFE_MARINE_MAPS_NAVIONICS_PLUS
  : props.translations.ITFE_MARINE_MAPS_NAVIONICS_PLUS_LAKES_LABEL));

const tableHeadingLeft = computed(() => (showGarminMaps.value
  ? props.translations.ITFE_MARINE_MAPS_NAVIONICS_VISION_PLUS
  : props.translations.ITFE_MARINE_MAPS_NAVIONICS_PLATINUM_PLUS_LAKES_LABEL));

const translatedFeatures = computed(() => [tableHeadingRight.value, tableHeadingLeft.value]);

const getShadedRelief = computed(() => props.activeLake?.features?.includes('shaded_relief'));
const getReliefShading = computed(() => props.activeLake?.features?.includes('relief_shading'));
const shouldDisplayLakeDetails = computed(() => !props.isLoading && props.showLakeInfo);

/* Handle the features list -> Sorts the features list */
const sortByFeature = (feature, array) => {
  const featureIndex = array.findIndex((element) => element === feature);
  if (featureIndex >= 0) {
    array.unshift(array.splice(featureIndex, 1)[0]);
  }
  return array;
};

/* Handle the features list */
const features = computed(() => {
  // shaded_relief and relief_shading should show the same translated value
  const featureTranslations = [
    { name: 'dynamic_lake_level', value: props.translations.ITFE_MARINE_MAPS_DYNAMIC_LAKE_LEVEL },
    { name: 'dem', value: props.translations.ITFE_MARINE_MAPS_DEM },
    {
      name: 'ngsr',
      value: !showGarminMaps.value
        ? props.translations.ITFE_MARINE_MAPS_NAVIONICS_NGSR_NAVIONICS
        : props.translations.ITFE_MARINE_MAPS_NGSR,
    },
    { name: 'shaded_relief', value: props.translations.ITFE_MARINE_MAPS_RELIEF_SHADING },
    { name: 'relief_shading', value: props.translations.ITFE_MARINE_MAPS_RELIEF_SHADING },
    { name: 'sidescan_mosaics', value: props.translations.ITFE_MARINE_MAPS_SIDESCANE_MOSIACS },
    { name: 'navionics_3d', value: props.translations.ITFE_MARINE_MAPS_NAVIONICS_THREE_D },
  ];

  // Filters out 'sonar_chart_shading'
  let condensedFeatureList = props.activeLake?.features?.filter((feature) => !!feature && feature !== 'sonar_chart_shading');

  // If both shaded_relief and relief_shading are features, only show one
  if (getShadedRelief.value && getReliefShading.value) {
    condensedFeatureList = condensedFeatureList.filter((feature) => feature !== 'relief_shading');
  }

  const featureList = condensedFeatureList.map((item) => {
    const isTranslated = featureTranslations.find((innerItem) => innerItem.name === item);
    return isTranslated ? isTranslated.value : item;
  });

  // Moves ngsr (Auto Guidance) to the first index of the array when => Garmin
  sortByFeature(props.translations.ITFE_MARINE_MAPS_NGSR, featureList);
  // Moves nsgr (Route Guidance) to the first index of the array when => Navionics
  sortByFeature(props.translations.ITFE_MARINE_MAPS_NAVIONICS_NGSR_NAVIONICS, featureList);

  return featureList;
});

const ngsrFeatureTitle = computed(() => (!showGarminMaps.value
  ? props.translations.ITFE_MARINE_MAPS_NAVIONICS_NGSR_NAVIONICS
  : props.translations.ITFE_MARINE_MAPS_NGSR));
const dynamicLakeLevelTitle = props.translations.ITFE_MARINE_MAPS_DYNAMIC_LAKE_LEVEL;
</script>

<template>
  <div
    v-if="shouldDisplayLakeDetails"
    class="lake-details"
  >
    <g-copy class="lake-details__header">
      {{ props.translations.ITFE_MARINE_MAPS_INCLUDED_FEATURES }}
    </g-copy>
    <div class="lake-details__table">
      <div class="lake-details__table__inner">
        <div class="lake-details__table__inner--row header">
          <div class="lake-details__table__inner--cell" />
          <g-copy
            v-for="translatedFeature in translatedFeatures"
            :key="translatedFeature"
            type="detail"
            text-align="center"
            class="lake-details__table__inner--cell"
          >
            {{ translatedFeature }}
          </g-copy>
        </div>
      </div>
      <template
        v-for="feature in features"
        :key="feature"
      >
        <div class="lake-details__table__inner">
          <div class="lake-details__table__inner--row body-header">
            <g-copy
              type="detail"
              text-align="left"
              class="lake-details__table__inner--cell"
            >
              {{ feature }}
            </g-copy>
          </div>
          <div class="lake-details__table__inner--row body">
            <div class="lake-details__table__inner--cell" />
            <div class="lake-details__table__inner--cell">
              <span
                v-if="feature === ngsrFeatureTitle || feature === dynamicLakeLevelTitle"
                class="checkmark"
              />
            </div>
            <div class="lake-details__table__inner--cell">
              <span class="checkmark" />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.lake-details {

  &__header {
    margin: 0.25rem 1.25rem;
    @include font-size(root);
    @include font-primary-weight-medium();
  }

  &__table {
    display: flex;
    flex-direction: column;
    background: $color-white;
    margin: 0.75rem 1.25rem;

    &__inner {
      display: flex;
      flex-direction: column;

      &--row {
        display: flex;

        &.header {
          @include font-primary-weight-bold();
          text-transform: capitalize;

          > div {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        &.body-header {
          font-size: 0.8rem;
          border-top: 1px solid $color-gray-90;
          @include font-primary-weight-bold();
          background-color: $color-gray-95;
          min-height: auto;
        }

        &.body {

          > div {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 35px;
          }
        }
      }

      :deep(.g__copy) {
        margin-bottom: 0;
      }

      &--cell {
        flex: 1;
        padding: 0.3rem 0.5rem;

        .checkmark {
          background: image-checkmark-black() center center no-repeat;
          height: 0.75rem;
          width: 0.75rem;
        }
      }
    }
  }
}
</style>
