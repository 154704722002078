import gql from 'graphql-tag';

export default gql`
  query (
    $lat: String!
    $long: String!
    $locale: String!
    $isGarminMap: Boolean!
  ) {
    getMapCardsByLatLong(
      lat: $lat
      long: $long
      locale: $locale
      isGarminMap: $isGarminMap
    ) {
      skuList
      sku
      priceSku
      buyUrl
      skuBuyUrl
      formattedPrice
      sortPrice
      name
      mapType
      isEms
      hasPriceVariation
      polygon {
        dashed
        type
        polygonCoordinates
        multiPolygonCoordinates
      }
    }
  }
`;
