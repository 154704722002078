<script setup>
/* Imports */
import CloseIcon from '@garmin/regalia/icons/close/icon.vue';

/* Props */
const props = defineProps({
  isMobile: {
    type: Boolean,
    default: false,
  },
  toggleMapSettings: {
    type: Function,
    default: () => {},
  },
});
</script>

<template>
  <div
    v-if="props.isMobile"
    tabindex="0"
    class="close-icon-map-settings__g-icon-close"
    data-testid="close-icon-map-settings"
    @click="props.toggleMapSettings"
  >
    <CloseIcon />
  </div>
</template>

<style lang="scss" scoped>
.close-icon-map-settings {

  &__g-icon {
    z-index: 1;
    fill: $color-black;
    cursor: pointer;
    stroke-width: 133px;

    &-close {
      width: 1.5rem;
      height: 1.5rem;
      right: 1rem;
      top: 1rem;
      position: absolute;
    }
  }
}
</style>
