const UNIT_CONVERSIONS = {
  ft: {
    m: (value) => Math.floor(value / 3.2808),
    fath: (value) => Math.floor(value * 0.166667),
  },
  m: {
    ft: (value) => Math.floor(value * 3.2808),
    fath: (value) => Math.floor(value * 0.5468),
  },
  fath: {
    ft: (value) => Math.floor(value * 6),
    m: (value) => Math.floor(value * 1.8288),
  },
};

/**
 * Converts a value from one unit to another
 * @param {number} value - Value to convert
 * @param {string} from  - Unit to convert from
 * @param {string} to    - Unit to convert to
 * @returns {number}     - The converted value
 * @example
 * convertUnit(1, 'ft', 'm'); // 0
 * convertUnit(1, 'm', 'ft'); // 3
 * convertUnit(1, 'fath', 'm'); // 1
 */
export const convertUnit = (value, from, to) => {
  if (value === 0 || value === '0' || !value) return 0;

  const conversionFunction = UNIT_CONVERSIONS[from]?.[to];
  return conversionFunction ? conversionFunction(value) : 0;
};

export default convertUnit;
