<script setup>
/* Imports */
import { computed, inject } from 'vue';

/* Inject */
const translations = inject('translations');
const searchType = inject('searchType');
const setSearchType = inject('setSearchType');

/* Handle search type change */
const changeSearchType = (e) => {
  setSearchType(e?.target?.value);
};

/* Computed properties */
const isLocationSearch = computed(() => searchType.value === 'location');
const isLakesSearch = computed(() => searchType.value === 'lakes');
</script>

<template>
  <div class="search-options">
    <g-copy class="search-options__label">
      {{ translations.ITFE_MARINE_MAPS_SEARCH_BY_TEXT }}
    </g-copy>
    <form class="search-options__form">
      <div class="search-options__radio">
        <g-radio :label="translations.ITFE_MARINE_MAPS_LOCATION_LABEL">
          <input
            id="locationSearch"
            name="searchType"
            type="radio"
            value="location"
            :checked="isLocationSearch"
            @change="changeSearchType($event)"
          >
        </g-radio>
        <g-radio :label="translations.ITFE_MARINE_MAPS_LAKES_LABEL">
          <input
            id="lakeSearch"
            name="searchType"
            type="radio"
            value="lakes"
            :checked="isLakesSearch"
            @change="changeSearchType($event)"
          >
        </g-radio>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.search-options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0.5rem;

  &__form {
    display: flex;
  }

  :deep(.g__copy) {
    margin: 0 0 0.5rem 0;
    @include font-primary-weight-bold();
  }
}

:deep(.g__radio__label) {
  padding: 0.5em 0;
  font-size: 0.938rem;
  @include font-primary-weight-regular();

  &::before {
    width: 1.2rem;
    height: 1.2rem;

    input[type = 'radio'] + & {
      position: relative;
      top: -1px;
    }
  }
}
</style>
