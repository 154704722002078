export default {
  state: {
    isSearched: false,
    drawerMode: 'bottom',
    isDrawerVisible: true,
    displayMobileSearch: false,
    displayMobileResults: true,
  },
  getters: {
    getIsSearched: (currentState) => currentState.isSearched,
    getDrawerMode: (currentState) => currentState.drawerMode,
    getIsDrawerVisible: (currentState) => currentState.isDrawerVisible,
    getDisplayMobileSearch: (currentState) => currentState.displayMobileSearch,
    getDisplayMobileResults: (currentState) => currentState.displayMobileResults,
  },
  mutations: {
    setIsSearched(state, isSearched) {
      state.isSearched = isSearched;
    },
    setDrawerMode(state, drawerMode) {
      state.drawerMode = drawerMode;
    },
    setIsDrawerVisible(state, payload) {
      state.isDrawerVisible = payload;
    },
    setDisplayMobileSearch(state, payload) {
      state.displayMobileSearch = payload;
    },
    setDisplayMobileResults(state, payload) {
      state.displayMobileResults = payload;
    },
  },
  actions: {
    setIsSearched({ commit }, payload) {
      commit('setIsSearched', payload);
    },
    setDrawerMode({ commit }, payload) {
      commit('setDrawerMode', payload);
    },
    setIsDrawerVisible({ commit }, payload) {
      commit('setIsDrawerVisible', payload);
    },
    setDisplayMobileSearch({ commit }, payload) {
      commit('setDisplayMobileSearch', payload);
    },
    setDisplayMobileResults({ commit }, payload) {
      commit('setDisplayMobileResults', payload);
    },
  },
};
