/* Imports */
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
} from 'vue';
import { breakpoints } from '../helpers/mainHelpers';

export const useScreenWidth = () => {
  const screenWidth = ref(window.innerWidth);

  const updateScreenWidth = () => {
    screenWidth.value = window.innerWidth;
  };

  onMounted(() => {
    window.addEventListener('resize', updateScreenWidth);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateScreenWidth);
  });

  const isMobile = computed(() => screenWidth.value < parseInt(breakpoints.sm, 10));
  const isTablet = computed(() => screenWidth.value >= parseInt(breakpoints.sm, 10) && screenWidth.value <= parseInt(breakpoints.md, 10));
  const isDesktop = computed(() => screenWidth.value > parseInt(breakpoints.md, 10));

  return {
    screenWidth,
    isMobile,
    isTablet,
    isDesktop,
  };
};

export default useScreenWidth;
