/**
 * Generates a time interval object based on the provided date.
 * The time interval includes durations of 2 years, 1 year, and 6 months ago.
 * @param {Date} date - The reference date for calculating the time interval.
 * @returns {object} An object containing time intervals for 2 years, 1 year, and 6 months ago, formatted appropriately
 */
export const timeIntervalGenerator = (date) => ({
  '2_years': {
    to: date.toISOString().replace(/\./g, ''),
    from: new Date(date.getFullYear() - 2, date.getMonth(), date.getDate())
      .toISOString()
      .replace(/\./g, ''),
  },
  '1_year': {
    to: date.toISOString().replace(/\./g, ''),
    from: new Date(date.getFullYear() - 1, date.getMonth(), date.getDate())
      .toISOString()
      .replace(/\./g, ''),
  },
  '6_months': {
    to: date.toISOString().replace(/\./g, ''),
    from: new Date(date.getFullYear(), date.getMonth() - 6, date.getDate())
      .toISOString()
      .replace(/\./g, ''),
  },
});

export default timeIntervalGenerator;
