<script setup>
/* Imports */
import { inject } from 'vue';

/* Props */
const props = defineProps({
  translations: {
    type: Object,
    default: () => ({}),
  },
  depthUnit: {
    type: String,
    default: '',
  },
});

/* Inject */
const isHeatmap = inject('isHeatmap');

/* Emits */
const emit = defineEmits(['changeChartDepth']);

/* Methods */
const changeDepthUnit = (event) => {
  emit('changeChartDepth', event?.target?.value);
};
</script>

<template>
  <div
    v-if="!isHeatmap"
    class="map-settings-depth-unit"
    data-testid="map-settings-depth-unit"
  >
    <g-heading
      class="map-settings-depth-unit__heading"
      :content="props.translations.ITFE_MARINE_MAPS_DEPTH_UNIT_HEADING"
      heading-size="4"
    />
    <g-radio :label="translations.ITFE_MARINE_MAPS_UNIT_OF_MEASURE_FEET">
      <input
        name="depthUnit"
        type="radio"
        value="ft"
        :checked="depthUnit === 'ft'"
        @change="changeDepthUnit($event)"
      >
    </g-radio>
    <g-radio :label="translations.ITFE_MARINE_MAPS_UNIT_OF_MEASURE_METERS">
      <input
        name="depthUnit"
        type="radio"
        value="m"
        :checked="depthUnit === 'm'"
        @change="changeDepthUnit($event)"
      >
    </g-radio>
    <g-radio :label="translations.ITFE_MARINE_MAPS_UNITS_OF_MEASURE_FATHOM">
      <input
        name="depthUnit"
        type="radio"
        value="fath"
        :checked="depthUnit === 'fath'"
        @change="changeDepthUnit($event)"
      >
    </g-radio>
  </div>
</template>

<style lang="scss" scoped>
@include map-settings('map-settings-depth-unit');
</style>
