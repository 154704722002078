/* Imports */
import { provide } from 'vue';

/**
 * Composable function to provide multiple values to a component using the provide/inject API of Vue 3.
 * @returns {object} - An object with the `provideMultiple` function.
 */
export const useProvideMultiple = () => {
  const provideMultiple = (data) => {
    if (!data) return; // Exit early if no data is provided
    Object.entries(data).forEach(([key, value]) => provide(key, value));
  };

  return { provideMultiple };
};

export default useProvideMultiple;
