/**
 * Retrieves the user's geolocation coordinates using the Geolocation API.
 * @returns {Promise<Array<number>>} A Promise that resolves with an array containing the latitude and longitude coordinates if successful.
 */
export const getUserGeolocation = () => new Promise((resolve, reject) => {
  if (navigator.geolocation) {
    const onSuccess = (position) => {
      const latitude = position?.coords?.latitude;
      const longitude = position?.coords?.longitude;
      resolve([latitude, longitude]);
    };

    const onError = () => {
      /* eslint-disable-next-line no-console */
      console.error('Geolocation not shared');
      reject();
    };

    const geolocationOptions = {
      enableHighAccuracy: true,
    };

    navigator.geolocation.getCurrentPosition(onSuccess, onError, geolocationOptions);
  } else {
    /* eslint-disable-next-line no-console */
    console.error('Geolocation not supported');
    reject();
  }
});

export default getUserGeolocation;
