<script setup>
/* Imports */
import {
  ref,
  inject,
} from 'vue';
import router from '../router/router';

/* Inject */
const translations = inject('translations');
const lastViewedMapBrand = inject('lastViewedMapBrand');
const showGarminMaps = inject('showGarminMaps');
const submitSearch = inject('submitSearch');

const buttons = ref([
  {
    id: 'garmin',
    content: 'Garmin',
    onClick: (e, button) => {
      // eslint-disable-next-line no-use-before-define
      handleButtonSelection(button);
    },
    selected: showGarminMaps.value,
  },
  {
    id: 'another-brand',
    content: translations.value.ITFE_MARINE_MAPS_ANOTHER_BRAND,
    onClick: (e, button) => {
      // eslint-disable-next-line no-use-before-define
      handleButtonSelection(button);
    },
    selected: !showGarminMaps.value,
  },
]);

const handleButtonSelection = (clickedButton) => {
  // update maps query param with new product filter selection
  const existingQueryParams = Object.fromEntries(new URLSearchParams(window?.location?.search));
  // router.push is needed for back button functionality
  router.push({
    query: { ...existingQueryParams, maps: clickedButton.id },
  });
  // Save selected map brand to local storage
  lastViewedMapBrand.value = clickedButton.id;
};
</script>

<template>
  <div
    class="product-filter"
    data-testid="product-filter"
  >
    <g-button-group
      class="product-filter__button-group"
      :label="translations.ITFE_MARINE_MAPS_PRODUCT_FILTER"
      .buttons="buttons"
      data-testid="product-filter__button-group"
      @click="submitSearch"
    />
  </div>
</template>

<style lang="scss">
.product-filter {
  margin-bottom: 1rem;

  &__button-group {
    display: flex;
    margin-bottom: 1rem;

    button {
      width: 50%;
      padding: 0.875rem;
    }
  }
}
</style>
