<script setup>
/* Imports */
import { inject } from 'vue';

/* Inject */
const translations = inject('translations');
const noResults = inject('noResults');
const isError = inject('isError');
</script>

<template>
  <div class="search-errors">
    <!-- No Results -->
    <g-copy
      v-if="noResults"
      type="small"
      text-align="left"
      class="search-errors__no-results"
    >
      {{ translations.ITFE_MARINE_MAPS_RESULTS_NO_RESULTS }}
    </g-copy>

    <!-- Errors -->
    <g-copy
      v-if="isError"
      type="small"
      text-align="left"
      class="search-errors__error"
    >
      {{ translations.ITFE_MARINE_MAPS_RESULTS_ERROR }}
    </g-copy>
  </div>
</template>

<style lang="scss" scoped>
.search-errors {

  &__no-results,
  &__error {
    margin: 1rem 0;
    @include font-primary-weight-medium();
  }
}
</style>
